import { styled, th } from '@smooth-ui/core-sc';

const BgPrimary = styled.section`
  align-items: center;
  background-color: ${th('primary')};
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
`;

export default BgPrimary;
