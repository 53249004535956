import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, Grid, Row, styled } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';

import IconWrapper from '_platform/src/components/SvgIcon/IconWrapper';
import SvgIcon from '_platform/src/components/SvgIcon/SvgIcon';
import LoadingAnimation from '_platform/src/components/Loading/LoadingAnimation';

import BannerHome from '../../components/CustomComponents/Backgrounds/BannerHome';
import BgPrimary from '../../components/CustomComponents/Backgrounds/BgPrimary';

import bannerMobile from './images/hp-home-banner-mobile-@1x.jpg';
import bannerMobile2x from './images/hp-home-banner-mobile-@2x.jpg';
import bannerTablet from './images/hp-home-banner-tablet-@1x.jpg';
import bannerTablet2x from './images/hp-home-banner-tablet-@2x.jpg';
import bannerDesktop from './images/hp-home-banner-@1x.jpg';
import bannerDesktop2x from './images/hp-home-banner-@2x.jpg';
import LoadAsync from '_platform/src/utils/LoadAsync';

const MastercardProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "MastercardProvider" */ '../MastercardProvider/MastercardProvider'
  )
);
const StatementMastercardBalance = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementLoyalty" */ '../../components/StatementMastercard/StatementMastercardBalance'
  )
);

const AchieveContainer = styled.section`
  background-color: rgba(43, 65, 90, 0.3);
  color: #2b415a;

  h2 {
    font-weight: bold;
    font-size: 36px;
  }

  h3 {
    font-weight: bold;
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
    font-weight: normal;
  }

  .border {
    border-top: 1px solid #2b415a;
  }
`;

const HomePage = ({ settings }) => {
  return (
    <div>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>
      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <BannerHome
          pageTitle={
            <React.Fragment>
              Welcome to
              <br />
              <strong>HP Commercial Club</strong>
            </React.Fragment>
          }
          pageText={
            <React.Fragment>
              <p>
                We are excited to launch HP Commercial Club – A new, streamlined
                way to reward our Partners for your sales. New look, new
                incentive agency, new incentives and overall smoother process
                for our Partners to be rewarded.
              </p>
              <p>
                Once registered to participate you will receive a HP reloadable
                Mastercard&reg;. Sell HP products to earn reward dollars. Each
                fortnight sales are loaded to the website and your Company
                Allocator will award them to registered team members. Your
                Reward Dollars will then be automatically loaded onto your HP
                Mastercard&reg;.
              </p>
            </React.Fragment>
          }
          bannerMobile={bannerMobile}
          bannerMobile2x={bannerMobile2x}
          bannerTablet={bannerTablet}
          bannerTablet2x={bannerTablet2x}
          bannerDesktop={bannerDesktop}
          bannerDesktop2x={bannerDesktop2x}
        />
        <BgPrimary>
          <MastercardProvider
            fetch={[
              {
                endpoint: '/Statement/v1/Eml',
                section: 'mastercard',
              },
            ]}
            render={({ mastercard }) =>
              !mastercard || !mastercard.data ? (
                <LoadingAnimation /> // Loader instead of `null`?
              ) : (
                <StatementMastercardBalance
                  statement={mastercard.data.mastercard}
                />
              )
            }
          />
        </BgPrimary>
        <AchieveContainer>
          <Grid>
            <Row justifyContent="center" className="spacer--top">
              <Col className="text--center">
                <h2>How to Achieve</h2>
              </Col>
            </Row>
            <Row className="spacer--bottom">
              <Col sm={6} lg={3}>
                <IconWrapper>
                  <SvgIcon
                    icon="clipboard-icon"
                    scaleWidth="48"
                    scaleHeight="64"
                    width="114px"
                    height="144px"
                    fill="#2B415A"
                    title="Register icon"
                  />
                </IconWrapper>
                <h3 className="text--center">Step 1</h3>
                <h4 className="text--center">Register</h4>
              </Col>
              <Col sm={6} lg={3}>
                <IconWrapper>
                  <SvgIcon
                    icon="mastercard-2"
                    scaleWidth="64"
                    scaleHeight="47"
                    width="146px"
                    height="106px"
                    fill="#2B415A"
                    title="Mastercard icon"
                  />
                </IconWrapper>
                <h3 className="text--center">Step 2</h3>
                <h4 className="text--center">
                  Receive HP <strong>Reloadable Mastercard &reg;</strong>
                </h4>
              </Col>
              <Col sm={6} lg={3}>
                <IconWrapper>
                  <SvgIcon
                    icon="HP-logo"
                    scaleWidth="64"
                    scaleHeight="64"
                    width="114px"
                    height="114px"
                    fill="#2B415A"
                    title="HP icon"
                  />
                </IconWrapper>
                <h3 className="text--center">Step 3</h3>
                <h4 className="text--center">
                  Sell HP Products to earn <strong>Reward Dollars</strong>
                </h4>
              </Col>
              <Col sm={6} lg={3}>
                <IconWrapper>
                  <SvgIcon
                    icon="dollar-icon"
                    scaleWidth="33"
                    scaleHeight="64"
                    width="52px"
                    height="100px"
                    fill="#2B415A"
                    title="Dollar icon"
                  />
                </IconWrapper>
                <h3 className="text--center">Step 4</h3>
                <h4 className="text--center">
                  <strong>Reward Dollars loaded</strong> to your Mastercard&reg;
                </h4>
              </Col>
            </Row>
            <Row justifyContent="center" className="border spacer">
              <Col className="text--center">
                <h2>Participating Products</h2>
                <p>
                  The range of core products can be{' '}
                  <Link to="/products/">
                    <strong>viewed here</strong>
                  </Link>
                  .
                </p>
              </Col>
            </Row>
          </Grid>
        </AchieveContainer>
      </PrivateComponent>
    </div>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
