import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Row, Col, css, styled, thd, up } from '@smooth-ui/core-sc';

const BannerWrapper = styled.div`
  position: relative;
  z-index: -1;
`;

const BannerContainer = styled.div`
  ${props =>
    props.bannerMobile
      ? `background-image: url(${props.bannerMobile});`
      : null};
  align-items: center;
  background-color: ${thd('primary', '#00A7AD')};
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 540px;
  justify-content: flex-start;

  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    ${props =>
      props.bannerMobile || props.bannerMobile2x
        ? `background-image: url(${props.bannerMobile2x ||
            props.bannerMobile});`
        : null};
  }

  .banner__title {
    color: #fff;
    font-size: 40px;
    font-weight: normal;
    line-height: 44px;
    position: relative;
    text-align: center;
  }

  .banner__text {
    color: #fff;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    position: relative;
    text-align: left;
  }

  .banner {
    height: 540px;
  }
  ${up(
    'sm',
    css`
      ${props =>
        props.bannerTablet
          ? `background-image: url(${props.bannerTablet});`
          : null};
      background-position: left center;
      height: 540px;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        ${props =>
          props.bannerTablet || props.bannerTablet2x
            ? `background-image: url(${props.bannerTablet2x ||
                props.bannerTablet});`
            : null};
      }

      .banner {
        height: 540px;
      }
    `
  )}
  ${up(
    'lg',
    css`
      ${props =>
        props.bannerDesktop
          ? `background-image: url(${props.bannerDesktop});`
          : null};
      background-position: center center;
      height: 540px;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        ${props =>
          props.bannerDesktop || props.bannerDesktop2x
            ? `background-image: url(${props.bannerDesktop2x ||
                props.bannerDesktop});`
            : null};
      }

      .banner {
        height: 500px;
      }
    `
  )}
`;

const BannerHome = props => (
  <BannerWrapper className="banner-wrapper">
    <BannerContainer {...props}>
      <Grid>
        <Row alignItems="center" justifyContent="center" className="banner">
          <Col xs={12} md={8} className="banner__title__wrapper">
            <p className="banner__title">{props.pageTitle}</p>
            <div className="banner__text">{props.pageText}</div>
          </Col>
        </Row>
      </Grid>
    </BannerContainer>
  </BannerWrapper>
);

BannerHome.propTypes = {
  pageTitle: PropTypes.object.isRequired,
  pageText: PropTypes.object.isRequired,
};

export default BannerHome;
